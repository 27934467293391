/*
To start importing other CSS files remove this line with comments and use syntax below
@import '../../node_modules/vendor/dist/dist.css';
*/

@import "_bootstrap.min.css";

@import "_themify-icons.css";

@import "_animate.css";

@import "_style.css";

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #features .col-sm-12 .services-icon,
  #services .col-sm-12 .services-icon {
    float: left;
    width: 9%;
  }

  #features .col-sm-12 .services-info,
  #services .col-sm-12 .services-info {
    float: right;
    width: 91%;
  }
}

.portfolio-detail a {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #ef3340;
}

.portfolio-detail a:hover {
  color: black;
}

#contactForm a {
  color: black;
}

#contactForm a:hover,
#contactForm a:focus,
#contactForm a:active {
  cursor: pointer;
  color: #ef3340;
}

#msgSubmit {
  margin-top: 3rem;
}

footer a {
  color: #89898b;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.cookie-info {
  display: none;
  position: fixed;
  z-index: 900;
  bottom: 10px;
  right: 10px;
  width: 300px;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 10px;
}

.cookie-info p {
  color: #ccc;
  text-align: center;
  padding: 0;
  margin: 0;
}

.cookie-info a {
  color: #fff;
}

.cookie-info a:hover {
  text-decoration: underline;
}

#cookie-button {
  display: inline-block;
  margin: 1rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  color: #fff;
  border: 1px solid #fff;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
}

#cookie-button:hover {
  text-decoration: none;
  color: #000;
  background-color: #fff;
}

.terms p a {
  text-decoration: underline;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.terms p a:hover,
.terms p a:focus,
.terms p a:active {
  color: #ef3340;
  text-decoration: underline;
}

.terms u {
  color: #141318;
  text-decoration: none;
}