/*

Author: radekthemes
Template: Granda - One Page Parallax
Version: 1.0

Table of contents:
1. General styles
2. Typography
3. Helpers
4. Preloader
5. Buttons
6. Go up button
7. Social-list
8. Specing
9. Header and navigation
10. Intro - Banner
11. About
12. About-image
13. Facts
14. Portfolio
15. Portfolio-contact
16. Team
17. Services
18. Pricing
19. Testimonials
20. Clients
21. Blog
22. Contact
23. Footer
24. Extra pages
24.1. Single work page
24.2. Single post page
25. Responsive

*/
/* ----------------------------------------
   ----------- 1.GENERAL STYLES -----------
   ---------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Rajdhani:500,600,700&subset=latin-ext");

html,
body {
  height: 100%;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  /* Lato */
  font-family: "Rajdhani", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
body.no-scroll {
  overflow: hidden;
}
input::-moz-focus-inner {
  border: 0;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
/* ----------------------------------------
   -------------- 2.TYPOGRAPHY ------------
   ---------------------------------------- */
h1,
h2,
h3,
h4,
h5,
a {
  color: #141318;
  font-family: "Rajdhani", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 39px;
  line-height: 59px;
  font-weight: 600;
  /* letter-spacing: -0.05rem; */
  text-align: left;
}
h2 {
  font-weight: 600;
  font-size: 39px;
  line-height: 59px;
  /* letter-spacing: -0.05em; */
}
h3 {
  font-size: 16px;
  font-weight: 600;
}
h4 {
  font-size: 12px;
  /* letter-spacing: -0.05rem; */
}
p {
  color: #89898b;
  line-height: 24px;
  font-size: 16px;
}
a {
  text-decoration: none;
  /* font-size: 12px; */
  font-weight: 600;
  outline: none !important;
  cursor: pointer;
}
a:hover,
a:focus,
a:active {
  color: #fff;
  text-decoration: none;
  outline: none !important;
}
/* ----------------------------------------
   --------------- 3.HELPERS --------------
   ---------------------------------------- */
.no-padding {
  padding: 0;
  overflow-x: hidden;
}
.img-full {
  width: 100%;
}
.section-title {
  margin-bottom: 96px;
}
.section-title p {
  margin-top: 16px;
}
/* ----------------------------------------
   -------------- 4.PRELOADER -------------
   ---------------------------------------- */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}
.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preloader-icon {
  width: 72px;
  height: 72px;
  display: inline-block;
  padding: 0px;
}
.preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-color: #ef3340;
  -webkit-animation: preloader-fx 1.6s linear infinite;
  animation: preloader-fx 1.6s linear infinite;
}
.preloader-icon span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}
@keyframes preloader-fx {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
/* ----------------------------------------
   -------------- 5.BUTTONS ---------------
   ---------------------------------------- */
.btn {
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 14px 36px;
}
.btn-light {
  background-color: #ef3340;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.btn-light:hover,
.btn-light:active,
.btn-light:focus,
.btn-light:active:focus,
.btn-light.active.focus,
.btn-light.active:focus,
.btn-light.focus,
.btn-light:active:focus,
.btn-light:focus {
  background-color: #2f2a2a;
  color: #fff;
  outline: none;
}
.btn-dark {
  background-color: #2f2a2a;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active.focus,
.btn-dark.active:focus,
.btn-dark.focus,
.btn-dark:active:focus,
.btn-dark:focus {
  background-color: #ef3340;
  color: #fff;
  outline: none;
}
/* ----------------------------------------
   ------------ 6.GO UP BUTTON ------------
   ---------------------------------------- */
.button-up {
  display: none;
  position: fixed;
  bottom: 36px;
  right: 36px;
  z-index: 40;
}
.button-up span {
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background-color: #2f2a2a;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.button-up span:hover,
.button-up span:focus {
  background-color: #ef3340;
}
/* ----------------------------------------
   ------------ 7.SOCIAL-LIST -------------
   ---------------------------------------- */
.social-list li {
  display: inline-block;
  margin: 0 3px;
}
.social-list li a {
  color: #fff;
  font-size: 12px;
}
.social-list li:first-child {
  margin-left: 0;
}
.social-list li:last-child {
  margin-right: 0;
}
.social-list li a span {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #2f2a2a;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.social-list li a span:hover {
  background-color: #ef3340;
}
/* ----------------------------------------
   -------------- 8.SPECING ---------------
   ---------------------------------------- */
.pd-t-b-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}
.pd-t-96 {
  padding-top: 96px;
}
.pd-t-b-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.pd-t-b-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.pd-t-b-144 {
  padding-top: 144px;
  padding-bottom: 144px;
}
/* ----------------------------------------
   -------- 9.HEADER AND NAVIGATION -------
   ---------------------------------------- */
.navbar-fixed-top {
  z-index: 99;
}
.navbar-default {
  background-color: transparent;
  border-color: transparent;
  padding: 36px 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navbar-default .navbar-brand .logo_dark {
  display: none;
  height: 100%;
}
.navbar-default .navbar-brand .logo_light {
  display: block;
  height: 100%;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  margin-top: 4px;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
  font-weight: 700;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 14px;
  padding: 10px 18px;
}
.navbar-default .navbar-nav > li > a:hover {
  color: #ef3340 !important;
  background-color: transparent;
}
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:focus,
.navbar-default .navbar-nav > li.active > a:hover {
  color: #ef3340 !important;
  background-color: transparent;
}
.top-nav-collapse {
  background-color: #fff;
  padding: 18px 0;
  box-shadow: 0 0 6px #b8b8b9;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.top-nav-collapse .navbar-nav > li > a,
.navbar-default.nav-portfolio-detail .navbar-nav > li > a {
  color: #141318;
}
.top-nav-collapse .navbar-brand .logo_dark {
  display: block;
}
.top-nav-collapse .navbar-brand .logo_light {
  display: none;
}
.navbar-default .navbar-toggle {
  border: none;
}
.navbar-default.nav-portfolio-detail .navbar-brand .logo_light {
  display: none;
}
.navbar-default.nav-portfolio-detail .navbar-brand .logo_dark {
  display: block;
}
.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}
.top-nav-collapse .navbar-toggle .icon-bar {
  background-color: #2f2a2a;
}
/* ----------------------------------------
   --------------- 10.INTRO ---------------
   ---------------------------------------- */
.intro {
  width: 100%;
  height: 100vh;
  position: relative;
}
/*Video background */
.intro-video {
  overflow: hidden;
}
.intro-video .video-bg {
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
}
.intro-video .video-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 19, 24, 0.7);
  z-index: 20;
}
.intro .intro-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;
}
.intro .intro-msg h1,
.intro .intro-msg h2,
.intro .intro-msg h3 {
  color: #fff;
}
.intro .intro-msg h1 {
  margin-top: 6px;
  margin-bottom: 40px;
  text-align: left;
}
.intro .intro-msg h2 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 600;
}

.intro .intro-msg .btn-light {
  margin-right: 2px;
  border: 2px solid #ef3340;
  font-size: 14px;
  font-weight: 700;
}
.intro .intro-msg .btn-light:hover,
.intro .intro-msg .btn-light:active,
.intro .intro-msg .btn-light:focus {
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: #ef3340;
}

/* ----------------------------------------
   --------------- 11.ABOUT ---------------
   ---------------------------------------- */
.about .about-left {
  padding-right: 20px;
}
.about .about-left h2 {
  margin-top: -6px;
  margin-bottom: 36px;
}
.about .about-left h2 span {
  color: #ef3340;
}
.about .about-right {
  padding-left: 20px;
}
.about .about-right .skills-bars {
  margin-top: 36px;
}
.about .about-right .skills-bars .progress-lt {
  margin-top: 24px;
}
.about .about-right .skills-bars .progress-lt:first-child {
  margin-top: 0;
}
.about .about-right .skills-bars .progress-lt h4 {
  display: inline-block;
  text-align: right;
  width: 48%;
  margin-right: 1%;
  color: #141318;
}
.about .about-right .skills-bars .progress-lt h4:first-child {
  text-align: left;
}
.about .about-right .skills-bars .progress-lt .progress {
  margin-bottom: 0;
  height: 8px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 16px;
}
.about .about-right .skills-bars .progress-lt .progress-bar {
  background-color: #ef3340;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 1.2s ease;
  transition: width 1.2s ease;
}
/* ----------------------------------------
   ------------ 12.ABOUT-IMAGE ------------
   ---------------------------------------- */
.about-image {
  max-width: 100%;
  height: 320px;
  background: url(../img/about.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
/* ----------------------------------------
   ------------ 14.PORTFOLIO --------------
   ---------------------------------------- */
.portfolio {
  background-color: #f7f7f7;
}
.portfolio .section-title {
  margin-bottom: 30px;
}
.filter-button {
  display: inline-block;
  float: right;
  margin-bottom: 30px;
  padding-top: 20px;
}
.filter-button li {
  display: inline-block;
  margin: 0 18px;
  text-transform: uppercase;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.filter-button li:hover,
.filter-button li.active {
  color: #ef3340;
}
.filter-button li:first-child {
  margin-left: 0;
}
.filter-button li:last-child {
  margin-right: 0;
}
.portfolio-spacing {
  margin-bottom: 30px;
}
.grid-sizer,
.portfolio-grid {
  width: 33.333333%;
  float: left;
  padding: 15px;
}
.portfolio-grid.big {
  width: 66.66666%;
}
.grid-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.grid-item .portfolio-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 51, 64, 0.9);
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #fff;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
}
.grid-item:hover .portfolio-overlay {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}
.grid-item .portfolio-overlay .portfolio-overlay-text {
  display: none;
}
.grid-item:hover .portfolio-overlay .portfolio-overlay-text {
  display: block;
}
.grid-item:hover .portfolio-overlay .portfolio-overlay-text {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
.grid-item:hover .portfolio-overlay .portfolio-overlay-text h3 {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  display: inline-block;
}
/* ----------------------------------------
   --------- 15.PORTFOLIO CONTACT ---------
   ---------------------------------------- */
.portfolio-contact {
  background: url(../img/parallax.jpg) center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.portfolio-contact h2 {
  margin-bottom: 30px;
  color: #fff;
}
/* ----------------------------------------
   ---------------- 16.TEAM ---------------
   ---------------------------------------- */
.team .member-image {
  margin-bottom: 28px;
}

.team .member-text h3 {
  margin-bottom: 4px;
}

.team .member-text p {
  margin-bottom: 16px;
  color: #5a595d;
  font-size: 14px;
}
/* ----------------------------------------
   ------------- 17.SERVICES --------------
   ---------------------------------------- */
.services {
  background: url(../img/parallax.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.services h2 {
  color: #fff;
}
.services .services-item {
  overflow: hidden;
}
.services .services-icon {
  float: left;
  width: 18%;
}
.services .services-icon span {
  font-size: 39px;
  color: #ef3340;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.services .services-info {
  float: right;
  width: 82%;
}
.services .services-info h3 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
.services .services-info p {
  font-size: 14px;
  color: #b8b8b9;
}
.services .services-spacing {
  margin-bottom: 60px;
}
/* ----------------------------------------
   -------------- 18.PRICING --------------
   ---------------------------------------- */
.pricing {
  background-color: #f7f7f7;
}
.pricing .pricing-tab {
  text-align: center;
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.pricing .pricing-tab.dark {
  background-color: #141318;
}
.pricing .pricing-tab.dark h3,
.pricing .pricing-tab.dark .price-value .currency,
.pricing .pricing-tab.dark .price-value .price,
.pricing .pricing-tab.dark .price-value .month {
  color: #fff;
}
.pricing .pricing-tab.dark .pricing-content ul li {
  color: #b8b8b9;
}
.pricing .pricing-tab .pricing-top {
  padding: 48px 0 24px;
}
.pricing .pricing-tab .price-value {
  margin-top: 16px;
}
.pricing .pricing-tab .price-value .currency,
.pricing .pricing-tab .price-value .month {
  font-size: 14px;
}
.pricing .pricing-tab .price-value .currency,
.pricing .pricing-tab .price-value .price,
.pricing .pricing-tab .price-value .month {
  /* Montserrat */
  font-family: "Rajdhani", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.pricing .pricing-tab .price-value .price {
  font-size: 49px;
  font-weight: 700;
}
.pricing .pricing-tab .pricing-content {
  padding-bottom: 48px;
}
.pricing .pricing-tab .pricing-content ul li {
  line-height: 36px;
  font-size: 14px;
  color: #89898b;
}
.pricing .pricing-tab .pricing-content .btn {
  margin-top: 36px;
}
/* ----------------------------------------
   ----------- 19.TESTIMONIALS ------------
   ---------------------------------------- */
.testimonials {
  background: url(../img/testimonials.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.testimonials .section-title {
  margin-bottom: 60px;
}
.owl-carousel .owl-item img {
  display: inline-block;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 0 0 36px;
}
.testimonials .item p {
  color: #b8b8b9;
}
.testimonials .item h3 {
  margin-top: 24px;
  margin-bottom: 12px;
  color: #fff;
}
.testimonials .item h4 span {
  color: #ef3340;
  font-weight: 600;
}
.testimonials .owl-carousel .owl-stage-outer {
  padding-bottom: 24px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #ef3340;
}
/* ----------------------------------------
   -------------- 20.CLIENTS --------------
   ---------------------------------------- */
.clients {
  background-color: #ef3340;
}
.clients .clients-spacing {
  margin-top: 48px;
}
.clients .clients-item {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.clients .clients-item .clients-logos {
  width: 60%;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.8;
}
/* ----------------------------------------
   --------------- 21.BLOG ----------------
   ---------------------------------------- */
.blog {
  background-color: #f7f7f7;
}
.blog-item img {
  width: 360px;
}
.blog-item .blog-content {
  background-color: #fff;
  padding: 30px 24px;
  width: 100%;
  max-width: 360px;
}
.blog-item .blog-content .post-meta {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 600;
  color: #222;
}
.blog-item .blog-content .post-meta a {
  font-size: 12px;
  color: #ef3340;
}
.blog-item .blog-content .post-meta a:hover {
  color: #222;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-item .blog-content .post-meta span {
  margin-left: 6px;
  color: #222;
}
.blog-item .blog-content .post-title a h3 {
  font-weight: 600;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-item .blog-content .post-title a:hover h3 {
  color: #ef3340;
}
.blog-item .blog-content .post-title {
  margin-bottom: 16px;
}
.blog-item .blog-content .post-content p {
  font-size: 14px;
}

/* ----------------------------------------
   -------------- 22.CONTACT --------------
   ---------------------------------------- */
.form-group {
  margin-bottom: 24px;
}
.form-control {
  background-color: #f7f7f7;
  color: #222;
  font-size: 14px;
  -webkit-box-shadow: none;
  -moz-webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 24px 18px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.form-control:hover,
.form-control:active,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid #ef3340;
}
textarea {
  resize: vertical;
  overflow: auto;
}
.help-block {
  display: none;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
}
.text-danger {
  font-size: 16px;
  display: inline-block;
  margin-top: 24px;
}
.has-error .form-control,
.has-error .form-control:focus {
  border: 2px solid #cd1a18;
  -webkit-box-shadow: none;
  -moz-webkit-box-shadow: none;
  box-shadow: none;
  color: #222;
}
.has-error .help-block,
.text-danger {
  font-weight: 700;
  color: #cd1a18;
  display: block;
}
.contact span {
  display: block;
  font-size: 20px;
  color: #ef3340;
  margin: 72px 0 12px;
}
.contact h3 {
  margin-bottom: 3px;
}
.contact p {
  color: #5a595d;
  font-size: 14px;
}
/* ----------------------------------------
   --------------- 23.FOOTER --------------
   ---------------------------------------- */
footer {
  background: #141318;
}
footer .social-list li a span {
  width: 42px;
  height: 42px;
  line-height: 42px;
}
footer p {
  margin: 24px 0 0 0;
  font-size: 14px;
}
/* ----------------------------------------
   ------------- 24.EXTRA PAGE ------------
   ---------------------------------------- */
/* ----------------------------------------
   ----------- 24.1.SINGLE WORK -----------
   ---------------------------------------- */
.portfolio-detail h3 {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 30px;
}
.portfolio-detail p {
  font-size: 14px;
}
.portfolio-detail p strong {
  color: #141318;
}
.portfolio-detail .item-description {
  margin-top: 36px;
  margin-bottom: 48px;
}
.portfolio-detail .item-description p {
  font-size: 16px;
}
.portfolio-bar {
  background-color: #ef3340;
  padding-top: 60px;
  padding-bottom: 60px;
}
.portfolio-bar a {
  color: #fff;
  opacity: 0.75;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.portfolio-bar a:hover {
  opacity: 1;
}
/* ----------------------------------------
   --------- 24.2.SINGLE POST PAGE---------
   ---------------------------------------- */
.intro-blog-post .intro-msg .post-meta {
  text-align: center;
  margin-top: 18px;
  font-size: 12px;
  color: #fff;
}
.intro-blog-post .intro-msg .post-meta a {
  margin: 0 8px;
  color: #fff;
}
.intro-blog-post .intro-msg .post-meta span {
  font-weight: 600;
}
.navbar-default .close-btn .navbar-right {
  margin-top: 7px;
}
.post .post-image {
  margin-bottom: 36px;
}
.post .post-detail .post-title {
  margin-bottom: 12px;
}
.post .post-detail .post-meta,
.post .post-detail .post-meta a {
  font-size: 14px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.post .post-detail .post-meta a:hover,
.intro-blog-post .intro-msg .post-meta a:hover {
  color: #ef3340;
}
.post .post-detail .post-meta span {
  margin-left: 12px;
  color: #7f7f7f;
}
.post .post-content {
  margin-top: 24px;
}
.post .post-content blockquote {
  font-size: 16px;
  background-color: #f4f4f4;
  border-left: 4px solid #ef3340;
  padding: 48px 36px;
  margin: 24px 0;
}
.post-tags {
  margin-top: 36px;
  margin-bottom: 36px;
}
.post-tags a {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  padding: 12px 24px;
  background-color: #2f2a2a;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-right: 4px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 8px;
}
.post-tags a:last-child {
  margin-right: 0;
}
.post-tags a:hover,
.post-tags a:focus {
  background-color: #ef3340;
}
.share {
  margin-bottom: 60px;
}
.share span {
  color: #89898b;
}
.share .social-list {
  display: inline-block;
}
.share .social-list li {
  margin: 0 4px;
}
.share .social-list li:first-child {
  margin-left: 18px;
}
.share .social-list li span {
  line-height: 36px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 14px;
  color: #fff;
}
.comments .comments-list {
  margin-top: 36px;
  overflow: hidden;
}
.comments .single-comment {
  margin-bottom: 36px;
  overflow: hidden;
}
.comments .single-comment .single-comment-image {
  float: left;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 40px;
}
.comments .single-comment-content {
  overflow: hidden;
}
.comments .single-comment-content .single-comment-content-head {
  overflow: hidden;
  margin-bottom: 10px;
}
.comments .single-comment-content .single-comment-content-head h4,
.comments .single-comment-content .single-comment-content-head span,
.comments .single-comment-content .single-comment-content-head a {
  float: left;
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.comments .single-comment-content .single-comment-content-head h4 {
  margin-right: 12px;
}
.comments .single-comment-content .single-comment-content-head span {
  margin-right: 12px;
}
.comments .single-comment-content .single-comment-content-head a {
  color: #ef3340;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.comments .single-comment-content .single-comment-content-head a:hover {
  color: #222;
}
.comments-respond {
  margin-top: 60px;
}
.comments-respond h3 {
  margin-bottom: 24px;
}
.sidebar {
  padding-left: 20px;
}
.sidebar h3 {
  margin-bottom: 18px;
}
.sidebar img {
  margin-bottom: 24px;
}
.sidebar .categories {
  margin-top: 36px;
  margin-bottom: 36px;
}
.sidebar .categories ul li {
  color: #7a7a7a;
  font-size: 12px;
  margin-bottom: 8px;
}
.sidebar .categories ul li span {
  float: right;
}
/* ----------------------------------------
   ------------- 25.RESPONSIVE ------------
   ---------------------------------------- */
/* Extra small devices (phones, less than 768px) */
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 37px;
  }
  .button-up {
    position: fixed;
    bottom: 12px;
    right: 12px;
  }
  .pd-t-b-96 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-t-b-120 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .pd-t-b-144 {
    padding-top: 84px;
    padding-bottom: 84px;
  }
  .intro-blog-post {
    height: 100%;
    text-align: center;
    background-attachment: scroll;
    background-size: cover;
  }
  .intro .intro-msg h1 {
    font-size: 23px;
    line-height: 28px;
    text-align: left;
    margin-top: 8px;
  }
  .intro .intro-msg h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .intro .intro-msg .btn-light {
    margin-right: 0;
    margin-bottom: 6px;
  }
  .section-title {
    margin-bottom: 60px;
  }
  .navbar-default {
    background-color: #fff;
    padding: 16px 0;
  }
  .navbar-toggle {
    margin-top: 12px;
  }
  .navbar-default .navbar-brand .logo_light {
    display: none;
  }
  .navbar-default .navbar-brand .logo_dark {
    display: block;
  }
  .top-nav-collapse .navbar-brand .logo_dark {
    display: block;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #141318;
  }
  .navbar-default .navbar-nav > li > a {
    color: #141318;
    font-weight: 700;
  }
  .navbar-default .close-btn {
    /* display: inline-block; */
    float: right;
    margin-top: -48px;
  }
  .navbar-default .close-btn .navbar-right {
    margin-top: 5px;
  }
  .intro,
  .testimonials {
    background-attachment: scroll;
  }
  .about h2 {
    text-align: left;
  }
  .about .about-right .skills-bars {
    margin-top: 48px;
  }
  .about p {
    text-align: left;
  }
  .about-image {
    background: url(../img/about_sm.jpg) center center no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }
  .facts-spacing {
    margin-bottom: 24px;
  }
  .services {
    padding-bottom: 48px;
  }
  .services-item {
    margin-bottom: 60px;
  }
  .services .services-icon {
    width: 8%;
  }
  .services .services-info {
    width: 92%;
    padding-left: 24px;
  }
  .services .services-spacing {
    margin-bottom: 0;
  }
  .portfolio-grid,
  .portfolio-grid.big,
  .portfolio-grid.small,
  .grid-sizer {
    width: 100%;
  }
  .grid-sizer,
  .portfolio-grid {
    padding: 7.5px 15px;
  }
  .portfolio-detail {
    padding-top: 108px;
  }
  .portfolio-detail .grid-item {
    margin-bottom: 30px;
  }
  .portfolio-detail .portfolio-spacing {
    margin-bottom: 0;
  }
  .portfolio-contact {
    background-attachment: scroll;
    background-size: cover;
  }
  .portfolio-contact h2 {
    line-height: normal;
    margin-bottom: 24px;
  }
  .portfolio-bar .pd-t-b-72 {
    padding: 48px 0;
  }
  .portfolio-page {
    padding-top: 108px;
    padding-bottom: 96px;
  }
  .filter-button {
    float: left;
    margin-bottom: 48px;
  }
  .team .team-spacing {
    margin-bottom: 48px;
  }
  .team .member-image img {
    margin: 0 auto;
  }
  .pricing-tab {
    max-width: 360px;
    margin: 0 auto;
  }
  .pricing-spacing {
    margin-bottom: 36px;
  }
  .clients .clients-item {
    width: 50%;
    height: auto;
    line-height: 72px;
    margin-bottom: 30px;
  }
  .blog-spacing {
    margin-bottom: 36px;
  }
  .blog-item {
    max-width: 360px;
    margin: 0 auto;
  }
  .blog-spacing:last-child {
    margin-bottom: 0;
  }
  .text-danger {
    display: block;
    margin-top: 24px;
  }
  .sidebar {
    margin-top: 72px;
    padding-left: 15px;
  }
  .contact span {
    margin-top: 36px;
  }
}
/* Small devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-default .navbar-nav > li > a {
    padding: 10px 14px;
    font-size: 12px;
  }
  .section-title {
    margin-bottom: 72px;
  }
  .about {
    text-align: left;
  }
  .about .about-right .skills-bars {
    margin-top: 72px;
  }
  .portfolio-contact h2 {
    line-height: normal;
    margin-bottom: 24px;
  }
  .portfolio-detail .grid-item {
    margin-bottom: 30px;
  }
  .portfolio-detail .portfolio-spacing {
    margin-bottom: 0;
  }
  .services-item {
    margin-bottom: 60px;
  }
  .services-spacing {
    margin-bottom: 0;
  }
}
/* Medium devices (desktops, 992px and up) */
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .portfolio-contact h2 {
    line-height: normal;
  }
}
